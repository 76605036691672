/* You can add global styles to this file, and also import other style files */
$colorTextLight: #ffffff;

body {
    display: block;
    margin: 0px;
    font-size: 14px;
    font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.card-box {
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    background-color: #fff;
    border-radius: 4px;
    padding: 30px!important;
    margin: 25px;
}
.k-checkbox-md
{
  height: 16px;
  width: 16px;
}
.k-tabstrip-items {
  padding-top: 25px;
  padding-bottom: 1px;
}
.k-tabstrip-items .k-item.k-state-active {
  color: #c90b51;
}

.k-tabstrip-items .k-item{
  border: 0px !important;

}
.k-tabstrip-content, .k-tabstrip > .k-content.k-state-active {
  border-color: rgba(0, 0, 0, 0.42);
  color: #424242;
  // background-color: #f2f2f2;
  color: #c90b51;
}
.mb-4 {
    margin: 1rem!important;
    max-width: 25%;
    flex: 0 0 25%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.k-floating-label-container {
    width: 100%;
}
.k-tabstrip-items-wrapper .k-item:active, .k-tabstrip-items-wrapper .k-item.k-active, .k-tabstrip-items-wrapper .k-item.k-selected{
  color:#c90b51
}

.k-link, .k-link:hover {
  color:#0000
}

.k-button[_ngcontent-whw-c5] {
    border-radius: 2px;
    padding: 8px 16px;
    margin-right: 55px;
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    font-size: 14px;
    line-height: 1.4285714286;
    font-family: "SegoeUI","Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;

    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
     user-select: none;
    cursor: pointer;
    outline: 0;
    position: relative;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    font-weight: 500;
    transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
}

.k-checkbox {
    border-color: rgba(0, 0, 0, 1);
    background-color: #ffffff;
    height: 16px;
    width: 16px;
    border-radius: 4px;
}
.k-checkbox-label{
    padding-top: 25px;
}
.k-picker-solid {
    border-width: 0!important;
    border-color: #fff!important;
    border-bottom: 1px solid rgba(0,0,0,.42)!important;
    min-height: 36px;
    border-radius: 0;
    padding: 0;
    width: 100%;
    border-style: solid;
    box-sizing: border-box;
    // cursor: default;
    outline: 0;
    background-color: #ffffff;
    position: relative;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.0));
}

.k-tabstrip-top > .k-tabstrip-items-wrapper .k-item:active, .k-tabstrip-top > .k-tabstrip-items-wrapper .k-item.k-active {
  margin-bottom: -1px;
  border-bottom-width: 7px;
  text-decoration: underline ;
  text-underline-offset: 11px;
  border-bottom-color: #c90b51 !important;
}

.k-tabstrip-items .k-link {
    padding-block: 6px;
    padding-inline: 12px;
    color: #005aa6;
    font-family: "SegoeUI","Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;

    cursor: pointer;
    display: inline-flex;
    vertical-align: middle;
    flex: 1 1 auto;
    flex-direction: row;
    align-content: center;
    align-items: center;
}
.k-link, .k-link:hover {
    color: #000;
    text-decoration: none;
    outline: 0;
    cursor: pointer;
}
.k-link ng-star-inserted{
    color: #fff !important;
  }

  .k-grid-md .k-table-th > .k-cell-inner > .k-link {
    padding-block: 8px;
    padding-inline: 12px;
    color: white;
}
  .k-tabstrip-items .k-link{
    color: #fff !important;
  }
  li>span .k-link ng-star-inserted{
    color: #fff !important;
  }
  .k-tabstrip-items-wrapper .k-item {
    color: #9c9b9b;
  }
  // .k-grouping-header > .k-grouping-drop-container {
  //   flex-grow: 1;
  //   padding-block: 8px;
  //   margin-block: -8px;
  //   display: inline-flex;
  //   align-self: stretch;
  //   align-items: center;
  //   font-family: "SegoeUI","Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;

// }
  div kendo-tabstrip {

    font-family: "SegoeUI","Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
    font-style: #204486;
    // background-color: #f2f2f2;
    color: #fff;
  }
  .k-tabstrip {
    border-width: 0;
    border-color: transparent;
    box-sizing: border-box;
    outline: 0;
    font-family: "SegoeUI","Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;

    font-size: 14px;
    line-height: 1.4285714286;
    color: #c90b51;
    // background-color: #f2f2f2;
    display: flex;
    flex-flow: column nowrap;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
  .headerCustomClass {
    color: #fff !important;
  }
  .kendo-grid-pdf{
    align-items: right;
    justify-content: right;

  }


   div kendo-grid-column {
    text-transform: capitalize;
  }
  div kendo-grid-header-column {
    text-transform: capitalize;
  }

  div kendo-grid .k-grid-toolbar {
    padding: 8px 3px !important;
    border-width: 1px;
  }

   div kendo-grid  {
   // width: calc(100% - 19px) !important;
    margin-left: 3px !important;
  }
  div k-grouping-header {
   //width: calc(100% - 19px) !important;
    margin-left: 3px !important;
  }
  div kendo-grid .k-grid-toolbar {
    padding: 8px 3px !important;
    border-width: 1px;
    justify-content: right;
  }
  .k-grid-toolbar {
    border-color: inherit;
    box-shadow: none;
    justify-content: right;
  }
  .k-toolbar {
    margin: 0;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    outline: 0;
    font-family: "SegoeUI","Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;

    font-size: 14px;
    line-height: 1.4285714286;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: right;
    position: relative;
    overflow: hidden;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .k-grid .k-table-th, .k-grid td, .k-grid .k-table-td {
    border-width: 0;
    border-inline-start-width: 1px;
    border-style: solid;
    font-family: "SegoeUI","Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    position: static;
    overflow: hidden;
    text-overflow: nowrap;
    box-sizing: content-box;
    word-wrap: break-word;
  }
  .nav-item{

    &.active{
      background-color: #005aa6;
      color: #0000;
    }

    &.hover{
      background-color: #e6d7d7;
      color: #0000;
    }
  }
  .k-tabstrip-items .k-link {
    color: #c90b51 !important;
}

.k-grid-md .k-table-th{
  background-color: #005AA6 !important;
}
.k-button-solid-primary {
  border-color: #c90b51;
  color: white;
  background-color: #c90b51;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}
.k-checkbox-label {
  padding-top: 0px;
}
.k-checkbox:checked, .k-checkbox.k-checked {
  border-color: #c90b51;
  color: white;
  background-color: #c90b51;
}
.k-popup {
  margin: 0;
  padding: 0;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 1.4285714286;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 250px;
}
.k-button-solid-primary:hover, .k-button-solid-primary.k-hover {
  border-color: #c90b51;
  background-color: #c90b51;
}
.textareawrapOnDeMand textarea.k-input-inner {
  margin: 0;
  height: 100px;
}

.textareawrap textarea.k-input-inner {
  margin: 0;
  height: 300px;
}
.textareawrap2 textarea.k-input-inner {
  margin: 0;
  height: 100px;
}
#textarea1{
  margin: 0;
  height: 100px;
}

// For dialog title
.k-dialog-titlebar {
  border-color: inherit;
  color: #fff;
  background-color: cornflowerblue;
}
.textareawrapmsg textarea.k-input-inner {
  margin: 0;
  height: 100px;
}

.modal-close-button{
  width: 70px;
  margin: auto;
  flex: 0 0 0%;
}

/*****kendo dropdown class started*********/
// .k-dropdown-wrap>.k-input, .k-dropdown .k-dropdown-wrap .k-select, .k-list, .k-reset {
//   background-color: lightblue;
// }
.k-dropdown-wrap>.k-input, .k-dropdown .k-dropdown-wrap .k-select, .k-list{
  // background-color: lightblue;
  // width: 100%;
  // flex: 0 0 0%;
  font-size: 14px;
  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

kendo-dropdownlist {
  width: 248px!important;
  // background-color: #fff;
  font-size: 14px;
  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

kendo-multiselect{
  width: 248px!important;
  // background-color: #fff;
  font-size: 14px;
  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
/*****kendo dropdown class ended*********/


.k-input-md, .k-picker-md {
  font-size: 14px !important;
  font-family: "SegoeUI", "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
  line-height: 1.4285714286;
}

/******Date time control css started**********/

.k-datetime-wrap .k-calendar, .k-datetime-wrap .k-timeselector {
  font-size: 11px !important;
  border-width: 1;
}

.k-calendar-th{
  font-size: 12px !important;
}
.k-calendar-header .k-calendar-title {
  font-weight: bold;
  font-size: 12px;
}

.k-button-flat-primary {
  color: #ff6358;
  font-size: 12px;
}

.k-calendar-container, .k-datetime-container {
  /* padding: 0; */
  width: 100% !important;
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.k-datetime-footer{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
/******Date time control css ended**********/

.k-input-spinner {
  width: calc(1.4285714286em + 8px);
  flex: none;
  display: none !important;
  // flex-flow: column nowrap;
}
.textareawrap .textareawrap{
  margin: 0;
  height: 300px;
}
#textarea1{
  margin:0;
  height:100px;
}

.k-grid .k-column-title {
  white-space: normal;
}


// .k-input {
// overflow: auto !important;
// }
kendo-grid-filter-menu-container{
  margin-left:-8px;
}
